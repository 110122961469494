@use "sass:list";
@use "generic";
@use "variables";

/**=====================
    44. Tabs CSS Start
==========================**/
.nav {
  .nav-link {
    cursor: pointer;
  }
}
.nav-pills {
  .nav-link.active, .show > .nav-link {
    background-color: variables.$primary-color;
  }
}

.nav{
  i{
    margin-right: 10px;
  }
}
.nav-tabs.tabs-color {
  .nav-item{
    .nav-link{
      color: variables.$primary-color;
    }
  }
  .nav-link.active, .nav-item.show .nav-link {
    color: variables.$theme-body-font-color;
  }
}
$nav-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$nav-color:variables.$primary-color,
variables.$primary-color,
variables.$secondary-color,
variables.$success-color,
variables.$danger-color,
variables.$info-color,
variables.$light-color,
variables.$dark-color,
variables.$warning-color;
@each $var in $nav-name {
  $i: list.index(generic.$label-name, $var);
  .nav-#{$var} , .nav-pills.nav-#{$var} {
    .nav-link{
      color: list.nth($nav-color, $i) !important;
    }
    .nav-link.active, .show > .nav-link {
      background-color: list.nth($nav-color, $i);
      color: variables.$white !important;
    }
  }
}
.nav-right{
  justify-content: flex-end;
}
.nav-tabs.nav-bottom {
  .nav-item {
    .nav-link.active,.nav-link.focus,.nav-link.hover{
      border-color: variables.$white variables.$light-color variables.$light-semi-gray;
    }
  }
}
.tab-content{
  ~ .nav-tabs{
    &.nav-material {
      margin-bottom: 0;
      margin-top: 30px;
    }
  }
}
// Material style css
.nav-material.nav-tabs{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  .nav-item{
    .nav-link{
      font-size: 16px;
      color: variables.$dark-color;
      text-align: center;
      padding:10px 25px;
      border: 0;
    }
  }
  .nav-link.active,.nav-item.show,.nav-link:focus,.nav-link:hover {
    color: variables.$primary-color;
    border-bottom: 2px solid variables.$primary-color !important;
  }
  .material-border{
    border-bottom: 2px solid variables.$primary-color;
    transition: all 0.3s ease;
    opacity: 0;
  }
  .nav-link.active ~ .material-border{
    transition: all 0.3s ease;
    opacity: 1;
  }
}
.nav-material.nav-left{
  .nav-link{
    border-left: 2px solid transparent;
    transition: all 0.3s ease;
    color: variables.$dark-color;
  }
  .nav-link.active,.show > .nav-link{
    background-color: transparent;
    border-left-color: variables.$primary-color;
    color: variables.$primary-color;
    border-radius: 0;
    transition: all 0.3s ease;
  }
}
.nav-material.nav-right{
  .nav-link{
    border-right: 2px solid transparent;
    transition: all 0.3s ease;
    color: variables.$dark-color;
    text-align: right;
  }
  .nav-link.active,.show > .nav-link{
    background-color: transparent;
    border-right-color: variables.$primary-color;
    color: variables.$primary-color;
    border-radius: 0;
    transition: all 0.3s ease;
  }
}
$m-nav-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$nav-color:variables.$primary-color,
variables.$primary-color,
variables.$secondary-color,
variables.$success-color,
variables.$danger-color,
variables.$info-color,
variables.$light-color,
variables.$dark-color,
variables.$warning-color;
@each $var in $m-nav-name {
  $i: list.index(generic.$label-name, $var);
  .nav-material.nav-#{$var} {
    .nav-link.active,.nav-item.show,.nav-link:focus,.nav-link:hover {
      color: list.nth($nav-color, $i);
      background-color: transparent;
    }
    .material-border{
      border-bottom: 2px solid list.nth($nav-color, $i);
    }
  }
  .nav-material.nav-left.nav-#{$var}{
    .nav-link.active,.show > .nav-link{
      border-left-color: list.nth($nav-color, $i);
      color: list.nth($nav-color, $i);
    }
  }
  .nav-material.nav-right.nav-#{$var}{
    .nav-link.active,.show > .nav-link{
      border-right-color: list.nth($nav-color, $i);
      color: list.nth($nav-color, $i);
    }
  }
}
.tabbed-card{
  ul{
    position: absolute;
    right: 15px;
    top: 22px;
    &.nav-material{
      &.nav-tabs{
        top: 0;
        .nav-item{
          float: right;
          .nav-link{
            padding: 28px 15px;
          }
        }
      }
    }
  }
}

/**=====================
     44. Tabs CSS Ends
==========================**/

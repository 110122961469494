@use "variables";

/**=====================
    40. Alertbox-call-out CSS start
==========================**/
.bs-callout-info {
    display: block;
    border-color: variables.$primary-color !important;
    background-color: variables.$primary-color;
    border-radius: 0.25rem;
    color: variables.$black;
}
/**=====================
     40. Alertbox-call-out CSS End
==========================**/

@use "sass:color";
@use "variables";

/**=====================
     50. Tree  CSS Start
==========================**/
.jstree-default{
	.jstree-clicked{
		background-color: color.adjust(variables.$primary-color , $lightness: 40%);
		box-shadow: none;
		color: variables.$dark-color;
	}
}
.vakata-context, .vakata-context ul{
	background-color: variables.$white;
	border: none;
	box-shadow: 0 0 2px 2px variables.$light-semi-gray;
	 li{
		 a{
			 .vakata-contextmenu-sep{
				 display: none;
			 }
			 &:hover{
				 background-color: color.adjust(variables.$primary-color , $lightness: 45%);
				 box-shadow: None;
			 }
		}
	}
	.vakata-context-separator{
		display: none;
	}
}
/**=====================
     50. Tree CSS Ends
==========================**/
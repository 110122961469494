@use "sass:color";
@use "variables";

/**=====================
     21. Select 2 CSS Start
==========================**/
.select2-drpdwn {
  .select2-selection {
    border-radius: 0!important;
    border-color: variables.$light-color!important;
    height: 40px!important;
    padding: 5px;
  }
  .form-control{
    border-radius: 5px;
  }
  .form-control-primary {
    border-color: variables.$primary-color;
    color: variables.$primary-color;
  }
  .form-control-secondary {
    border-color: variables.$secondary-color;
    color: variables.$secondary-color;
  }
  .form-control-success
  {
    border-color: variables.$success-color;
    color: variables.$success-color;
  }
  .form-control-info
  {
    border-color: variables.$info-color;
    color: variables.$info-color;
  }
  .form-control-warning
  {
    border-color: variables.$warning-color;
    color: variables.$warning-color;
  }
  .form-control-danger
  {
    border-color: variables.$danger-color;
    color: variables.$danger-color;
  }
  .form-control-inverse
  {
    border-color: variables.$dark-color;
    color: variables.$dark-color;
  }
  .form-control-primary-fill {
    background-color: variables.$primary-color;
    color: variables.$white;
  }
  .form-control-secondary-fill {
    background-color: variables.$secondary-color;
    color: variables.$white;
  }
  .form-control-success-fill{
    background-color: variables.$success-color;
    color: variables.$white;
  }
  .form-control-info-fill
  {
    background-color: variables.$info-color;
    color: variables.$white;
  }
  .form-control-warning-fill
  {
    background-color: variables.$warning-color;
    color: variables.$white;
  }
  .form-control-danger-fill
  {
    background-color: variables.$danger-color;
    color: variables.$white;
  }
  .form-control-inverse-fill
  {
    background-color: variables.$dark-color;
    color: variables.$white;
  }
}

//colors
$color_bon_jour_approx: #e0e0e0;
$color_cape_cod_approx: #424242;
.selection{
  .select2-selection{
    font-family: variables.$font-open-sans;
    border-radius: 5px !important;
    .select2-search__field{
      margin-top: 0;
      padding: 3px;
    }
  }
}
.select2-container--classic {
  .select2-selection--multiple {
    .select2-selection__choice {
      padding: 2px 6px !important;
      margin-top: 0 !important;
      background-color: variables.$primary-color !important;
      border-color: color.adjust(variables.$primary-color , $lightness: -10%) !important;
      color: variables.$white;
      margin-right: 8px !important;
    }
    .select2-selection__choice__remove {
      color: variables.$white !important;
      float: right;
      margin-left: 0.5rem;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $color_bon_jour_approx !important;
    color: $color_cape_cod_approx !important;
  }
  .select2-results__options .select2-results__option[aria-selected=true] {
    background-color: variables.$primary-color !important;
    color: variables.$white !important;
  }
}
.select2-container--default {
  .select2-selection--single{
    .select2-selection__arrow {
      top: 7px !important;
      right: 10px !important;
    }
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      padding: 2px 6px !important;
      margin-top: 0 !important;
      background-color: variables.$primary-color !important;
      border-color: color.adjust(variables.$primary-color , $lightness: -10%) !important;
      color: variables.$white;
      margin-right: 8px !important;
    }
    .select2-selection__choice__remove {
      color: variables.$white !important;
      float: right;
      margin-left: 0.5rem;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $color_bon_jour_approx !important;
    color: $color_cape_cod_approx !important;
  }
  .select2-results__options .select2-results__option[aria-selected=true] {
    background-color: variables.$primary-color !important;
    color: variables.$white !important;
  }
}

/**=====================
     21. Select 2 CSS Ends
==========================**/
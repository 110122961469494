@use "variables";

/**=====================
    57. breadcrumb CSS Start
==========================**/
.breadcrumb-colored{
	li{
		a{
			color:variables.$white;
		}
		&.active{
			color:variables.$white;
			opacity:0.7;
		}
	}
	.breadcrumb-item{
		&:before{
			color: variables.$white;
		}
	}
	.breadcrumb-item.txt-dark{
		&:before{
			color: variables.$black;
		}
	}
}
.breadcrumb-item{
	&.active{
		text-transform: capitalize;
	}
}
/**=====================
    57. breadcrumb CSS ends
==========================**/

@use "variables";

/**=====================
     22. User profile css start
==========================**/
.user-profile {
  .ttl-info {
    h6 {
      font-size: 12px;
      color: variables.$semi-dark;
    }
    span {
      font-size: 14px;
      color: variables.$theme-font-color;
      font-weight: 500;
    }
  }
  .border-right {
    border-right: 1px solid variables.$light-color;
  }
  hr {
    margin: 30px 0;
  }
  .hovercard {
    .cardheader {
      //background: url(/src/assets/images/bg-profile.png);
      background-size: cover;
      background-position: 10%;
      height: 470px;
    }
    .user-image {
      position: relative;
    .avatar {
      margin-top: -90px;
      img {
        width: 155px;
        height: 155px;
        max-width: 155px;
        max-height: 155px;
        border-radius: 50%;
        border: 10px solid variables.$white;
      }
    }
      .icon-wrapper {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: variables.$white;
        cursor: pointer;
        overflow: hidden;
        margin: 0 auto;
        font-size: 17px;
        &:hover
        {
          color: variables.$primary-color;
          font-size: 15px;
        }
      }
    }
    .info {
      padding: 30px;
      .title {
        margin-bottom: 4px;
        font-size: 24px;
        line-height: 1;
        color: variables.$dark-color;
        vertical-align: middle;
        a {
          color: variables.$theme-font-color;
          font-size: 24px;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      .desc {
        overflow: hidden;
        font-size: 14px;
        line-height: 20px;
        color: variables.$semi-dark;
        text-overflow: ellipsis;
        text-transform: uppercase;
      }
    }
  }
  .follow {
    margin-top: 20px;
    .follow-num {
      font-size: 36px;
      color: variables.$theme-font-color;
      font-family: variables.$font-open-sans;
    }
    span {
      color: variables.$semi-dark;
    }
  }
  .profile-img-style {
    padding: 30px;
    .img-container{
      margin-top: 30px;
    }
    .user-name {
      font-size: 14px;
      color: variables.$theme-font-color;
      margin: 0;
      font-weight: 600;
    }
    p {
      line-height: 30px;
      font-size: 14px;
      color: variables.$dark-gray;
      margin-bottom: 0;
      margin-top: 15px;
    }
    .media {
      img {
        width: 75px;
      }
    }
  }
  .like-comment {
    a{
      color: variables.$dark-gray;
      &:hover{
        color: variables.$danger-color;
      }
    }
    span {
      font-family: variables.$font-open-sans;
    }
  }
  .social-media {
    a {
      padding:0 15px;
      color: variables.$semi-dark;
      font-size: 24px;
      cursor: pointer;
      &:hover {
        color: variables.$primary-color;
      }
    }
  }
}
/**=====================
     22. User profile css Ends
==========================**/

@use "variables";

/**=====================
    33.  Ecommerce  CSS Start
==========================**/

/**====== Product Start ======**/
.payment-opt{
  display: flex;
  align-items: center;
  li{
    display: inline-block;
    img{
      height: 20px;
      margin: 15px 15px 0 0;
    }
  }
}
.credit-card{
  .text-center{
    img{
      margin-bottom: 20px;
    }
  }
}
.product-box{
  .product-details {
    padding: 25px;
    text-align: center;
    h6{
      color: variables.$theme-body-font-color;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    span{
      color: variables.$dark-gray;
    }
  }

  .product-img{
    position: relative;
    .product-hover{
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.7);
      opacity: 0;
      border-radius: 100%;
      transform: scale(0);
      transition: all 0.3s ease;
      ul{
        li{
          display: inline-block;
          box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
          padding: 10px 15px;
          background-color: variables.$white;
          font-size: 18px;
          border-radius: 5px;
          margin-right: 5px;
          &:hover{
            background-color: variables.$theme-body-font-color;
            color: variables.$white;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
  &:hover{
    .product-hover{
    opacity: 1;
    border-radius: 0%;
    transform: scale(1);
    transition: all 0.3s ease;
    }
  }
}
.product-page-main{
  padding: 30px;
  .owl-item{
    .item{
      border: 1px solid variables.$light-color;
      border-radius: 5px;
    }
    &.current{
      .item{
        border: 1px solid variables.$primary-color;
      }
    }
  }
  .product-slider{
    margin-bottom: 20px;
  }
}
.product-page-details{
  h4{
    color: variables.$theme-body-font-color;
    text-transform: uppercase;
    font-size: 18px;
  }
  .d-flex {
    .center-review {
      padding-top: 5px;
    }
  }
}
.br-theme-fontawesome-stars{
  .br-widget {
    a,a.br-selected:after,a.br-active:after {
      color: #ffa800;
      font: normal normal normal 14px/1 FontAwesome;
    }
  }
}
.product-price{
  font-size: 18px;
  font-weight: 700;
  font-family: variables.$font-open-sans;
  del{
    color: variables.$semi-dark;
    padding-right: 20px;
  }
}
.product-color{
  li{
    display: inline-block;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    margin: 0 2px;
  }
}
.product-page-main{
  p{
    font-size: 15px;
    &.m-t-20 {
      margin-top: 15px !important;
    }
  }
  .nav-link{
    text-transform: uppercase;
    font-weight: 600;
  }
  .slick-slide{
    &.slick-active{
      &.slick-current {
        border: 1px solid variables.$primary-color;
        border-radius: 4px;
      }
    }
  }
}
.slick-next,.slick-prev {
  &:before {
    display: none;
  }
}
.product-table {
  table {
    border: 1px solid #dee2e6;
    tbody {
      tr {
        td {
          &:nth-child(4) {
            min-width: 101px;
          }
          &:last-child {
            min-width: 240px;
          }
        }
      }
    }
  }
}
/**=====================
    33. Ecommerce  CSS End
==========================**/

@use "sass:color";
@use "login";
@use "variables";

/**=====================
    41. Chart CSS Start
==========================**/
//urls

%chart-1 {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}
%chart-2 {
  width: 35px;
  height: 35px;
  margin-left: 7.5px;
  margin-top: 7.5px;
  line-height: 35px;
}
%chart-3 {
  width: 20px;
  height: 20px;
  margin-left: 5.5px;
  margin-top: 4.5px;
  line-height: 21px;
}


.chart-block {
  canvas {
    width: 100% !important;
  }
  .chart-overflow {
    overflow: hidden;
    text-align: center;
  }
}
.candidcahrt {
  width: 100%;
  height: 470px;
}
.amcharts-export-canvas {
  position: absolute;
  display: none;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: variables.$white;
  .canvas-container {
    transition: opacity .5s ease-out;
  }
  &.active {
    display: block;
  }
  &.dropbox .canvas-container {
    opacity: .5;
  }
}
.flot-chart-container {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  #toggling-series-flot {
    width: 79% !important;
  }
}
.flot-chart-placeholder {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
  text-align: center;
  .legend table {
    border-spacing: 5px;
  }
}
.morris-hover {
  position: absolute;
  z-index: 8;
  &.morris-default-style {
    border-radius: 10px;
    padding: 6px;
    color: variables.$dark-gray;
    background: variables.$white;
    border: solid 1px variables.$light-gray;
    font-family: variables.$font-serif;
    font-size: 12px;
    text-align: center;
    .morris-hover-row-label {
      font-weight: bold;
      margin: 0.25em 0;
    }
    .morris-hover-point {
      white-space: nowrap;
      margin: 0.1em 0;
    }
  }
}
.chart-sparkline {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  background: color.adjust(variables.$black , $lightness: 60%) transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  color: variables.$white;
  font: 10px variables.$font_arial, variables.$font-serif;
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid variables.$white;
  box-sizing: content-box;
  z-index: 10000;
  .jqsfield {
    color: variables.$white;
    font: 10px variables.$font_arial, variables.$font-serif;
    text-align: left;
  }
}
.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
  font-family: variables.$font-open-sans, variables.$font-serif;
  background-clip: content-box;
  &:after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: variables.$color-fiord;
    content: attr(data-label);
    background-color: variables.$white;
    z-index: 1;
    width: 56px;
    height: 56px;
    margin-left: 12px;
    margin-top: 12px;
    line-height: 56px;
  }
  > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: variables.$color-fiord;
    z-index: 3;
    width: 56px;
    height: 56px;
    margin-left: 12px;
    margin-top: 12px;
    line-height: 56px;
  }
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$dark-primary 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-primary 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-primary {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$primary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$primary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-secondary {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$secondary-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$secondary-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-success {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$success-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$success-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-info {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$info-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$info-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-warning {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$warning-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$warning-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-danger {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$danger-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$danger-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-default {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$pitch-color 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-pink {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$flower-pink 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$pitch-color 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$flower-pink 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-purple {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$dark-sky 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$dark-sky 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar-inverse {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(90deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(108deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(126deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(144deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(162deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(180deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(198deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(216deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(234deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, variables.$light-semi-gray 50%, transparent 50%, transparent), linear-gradient(252deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, variables.$color-scarpa 50%, transparent 50%, transparent), linear-gradient(270deg, variables.$color-scarpa 50%, variables.$light-semi-gray 50%, variables.$light-semi-gray);
  }
}
.radial-bar{
  &.radial-bar-lg {
    width: 100px;
    height: 100px;
    font-size: 20px;
    &:after {
      @extend %chart-1;
    }
    > img {
      @extend %chart-1;
    }
  }
  &.radial-bar-sm {
    width: 50px;
    height: 50px;
    font-size: 12px;
    &:after {
      @extend %chart-2;
    }
    > img {
      @extend %chart-2;
    }
  }
  &.radial-bar-xs {
    width: 30px;
    height: 30px;
    font-size: 8px;
    &:after {
      @extend %chart-3;
    }
    > img {
      @extend %chart-3;
    }
  }
}
.pieLabel{
  div {
    color: variables.$white !important;
    input[type=checkbox] {
      margin: 7px;
    }
  }
}
/**=====================
     41. Chart CSS End
==========================**/
.page-sidebar.custom-scrollbar.page-sidebar-open {
  width: 280px;
}

.page-wrapper .sidebar-close .page-sidebar {
  transform: translate(-280px);
}

.page-wrapper .page-body-wrapper .page-body {
  padding-left: 40px;
}



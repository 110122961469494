@use "variables";

/**=====================
     16. Popover CSS Start
==========================**/
.popover-header {
  background-color: variables.$dark-color;
  color: variables.$white;
}
.popover-offset{
  top: -20px !important;
}
/**=====================
     16. Popover CSS Ends
==========================**/
@use "sass:color";
@use "variables";

/**=====================
     01. General CSS Start
==========================**/
//Custom css Start

body {
    background-color: rgba(246, 246, 246, 0.6);
    font-size: variables.$theme-font-size;
    overflow-x: hidden;
    font-family: variables.$font-raleway;
    color: variables.$theme-body-font-color;
}
ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}
* a {
    color: variables.$primary-color;
}
*.btn:focus{
    box-shadow : none !important;
}
p {
    font-size: 13px;
    line-height: 1.7;
    letter-spacing: 0.7px;
}
code{
    color: variables.$primary-color !important;
    background-color: variables.$light-color;
    padding: 3px;
    margin: 0 3px;
    border-radius: 2px;
}
blockquote{
    border-left: 4px solid variables.$light-gray;
    padding: 15px;
}
blockquote{
    &.text-center{
        border: none;
        padding: 15px;
    }
}
blockquote{
    &.text-right{
        border-left: none;
        border-right: 4px solid variables.$light-gray;
        padding: 15px;
    }
}
:focus{
    outline-color: variables.$primary-color;
}
.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.ace-editor {
    height: 400px;
    width: 100%;
}
.editor-toolbar
{
    width: 100% !important;
}
.CodeMirror
{
    top:0!important;
}

//Custom css End

//Typography css start

.typography {
    h1,.h1,
    h2,.h2,
    h3,.h3,
    h4,.h4,
    h5,.h5,
    h6,.h6 {
        margin: 20px 0;
        margin-top: 0;
        &:last-child{
            margin-bottom: 0;
        }
    }
    small {
        padding-left: 10px;
        color: variables.$dark-color;
    }
}
pre{
    background-color: #f9f7ff;
    padding: 20px 0;
}

a{
    &:hover{
        text-decoration: none;
    }
}
//Typography css end

.owl-theme {
    .owl-dots {
        .owl-dot{
            span{
                background: color.adjust(variables.$primary-color, $lightness: 20%);
            }
            &.active,&:hover {
                span {
                    background: variables.$primary-color;
                }
            }
        }
    }
    .owl-nav{
        &.disabled {
            &+ .owl-dots {
                margin-bottom: -10px;
                margin-top: 25px;
            }
        }
    }
}

.click2edit{
    &~.note-editor{
        &.note-frame{
            margin-bottom: 30px;
        }
    }
}
.note-editor{
    &.note-frame {
        border-color: variables.$light-color;
    }
}
.note-editor{
    &.note-frame {
        border-color: variables.$light-color;
    }
}
// modal page
.modal-dialog{
    .modal-content{
        .modal-body{
           p{
               a{
                   margin-right: 0;
               }
           }
        }
    }
}
.styles_overlay__CLSq-{
    .styles_modal__gNwvD{
        border-radius: 0.3rem;
        .styles_closeButton__20ID4{
            svg{
                width: 15px;
                height: 15px;
            }
        }
    }
}
// sweetalert page
.sweet-alert {
    h2 {
        font-size: 27px;
    }
    .lead {
        font-size: 16px;
    }
    .btn-lg {
        font-size: 14px;
    }
}
// slick slider
.slick-dots {
    position: unset;
    li {
        button{
            &:before {
                font-size: 12px;
                color: variables.$primary-color;
            }
        }
        &.slick-active {
            button{
                &:before {
                    color: variables.$primary-color;
                }
            }
        }
    }
}
// range slider
.rangeslider-horizontal, .rangeslider-vertical {
    .rangeslider__fill {
        background-color: variables.$primary-color !important;
    }
}
.vertical-height {
    .rangeslider-vertical {
        height: 325px;
    }
}
// toastr page
.Toastify__toast--success {
    background: variables.$success-color !important;
}
.Toastify__toast--info {
    background: variables.$info-color !important;
}
.Toastify__toast--warning {
    background: variables.$warning-color !important;
}
.Toastify__toast--error {
    background: variables.$danger-color !important;
}
// to-do app
.todo {
    .todo-list-wrapper {
        .todo-list-footer {
            .new-task-wrapper {
                h6 {
                    margin-bottom: 15px;
                }
                .mt-todo, .btn {
                    margin-top: 7px;
                }
            }
        }
    }
}
// react sidemenu
//
//.Side-menu {
//    width: auto;
//    max-width: 400px;
//    width: 100%;
//}
//.Side-menu .children {
//    transition: max-height 0.7s ease-in;
//    overflow: hidden;
//}
//.Side-menu .children.active {
//    transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
//    max-height: 9999px;
//}
//.Side-menu .children.inactive {
//    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1) -0.15s;
//    max-height: 0;
//}
//.Side-menu * {
//    box-sizing: border-box;
//}
//.Side-menu .divider {
//    height: 42px;
//    padding-top: 14px;
//    padding-left: 14px;
//    padding-right: 14px;
//    font-size: 12px;
//}
//.Side-menu.rtl .divider {
//    text-align: right;
//}
//.Side-menu.rtl .item .item-title .item-label {
//    float: right;
//}
//.Side-menu.rtl .item .item-title .item-icon {
//    margin-right: 0px;
//    margin-left: 10px;
//    margin-top: 3px;
//    float: right;
//}
//.Side-menu.rtl .item .item-title .fa-chevron-down,
//.Side-menu.rtl .item .item-title .fa-chevron-left,
//.Side-menu.rtl .item .item-title .fa-chevron-right {
//    float: left;
//}
//.Side-menu.rtl .item .children {
//    padding-left: 0;
//    padding-right: 25px;
//}
//.Side-menu .item .item-title {
//    height: 40px;
//    padding-top: 14px;
//    padding-left: 18px;
//    padding-right: 18px;
//    font-size: 14px;
//    cursor: pointer !important;
//}
//.Side-menu .item .item-title .item-icon {
//    margin-right: 10px;
//}
//.Side-menu .item .item-title .fa-chevron-down,
//.Side-menu .item .item-title .fa-chevron-left,
//.Side-menu .item .item-title .fa-chevron-right {
//    float: right;
//    position: relative;
//    font-size: 12px;
//    padding-top: 3px;
//}
//.Side-menu .item:hover > .item-title {
//    cursor: pointer;
//}
//.Side-menu .item.item-level-1 > .item-title {
//    height: 45px;
//}
//.Side-menu .item .children {
//    padding-left: 25px;
//}
//.Side-menu-default {
//    background-color: #f6f7fb;
//    color: #2a3142;
//}
//.Side-menu-default *:not(i) {
//    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
//    font-weight: 300;
//}
//.Side-menu-default .divider {
//    background-color: #f6f7fb;
//    color: #2a3142;
//    text-transform: uppercase;
//}
//.Side-menu-default .item a {
//    text-decoration: none;
//    color: #B8C7CE;
//}
//.Side-menu-default .item.active {
//    color: #2a3142;
//}
//.Side-menu-default .item.active .children {
//    color: #B8C7CE;
//}
//.Side-menu-default .item.active > .item-title > a {
//    color: #2a3142;
//}
//.Side-menu-default .item:hover > .item-title {
//    color: #2a3142;
//}
//.Side-menu-default .item:hover > .item-title a {
//    color: #2a3142;
//}
//.Side-menu-default .item.item-level-1:hover > .item-title {
//    color: #2a3142;
//}
//.Side-menu-default .item.item-level-1:hover,
//.Side-menu-default .item.item-level-1.active {
//    border-left: 4px solid #C4EAF2;
//}
/**=====================
      01. General CSS Ends
==========================**/

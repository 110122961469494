@use "sass:list";
@use "variables";

/**=====================
     32. Page CSS Start
==========================**/


/*test Nav bar color */
$page-name:theme,
primary,
secondary,
success,
danger,
info,
dark,
warning;
$page-color:variables.$primary-color,
variables.$primary-color,
variables.$secondary-color,
variables.$success-color,
variables.$danger-color,
variables.$info-color,
variables.$dark-color,
variables.$warning-color;
@each $var in $page-name {
  $i: list.index($page-name, $var);
  .pagination-#{$var}{
    .page-item{
      .page-link{
        color: list.nth($page-color, $i);
      }
      &.active{
        .page-link{
          color: variables.$white;
          background-color:  list.nth($page-color, $i);
          border-color:  list.nth($page-color, $i);
        }
      }
    }
  }
}
/**=====================
     32. Page CSS Ends
==========================**/
@use "variables";

/**=====================
     28. Radio CSS Start
==========================**/
.checkbox{
    label {
        display: inline-block;
        position: relative;
        padding-left: 16px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 19px;
            height: 19px;
            left: 0;
            margin-left: -16px;
            border: 1px solid variables.$light-gray;
            border-radius: 3px;
            background-color: variables.$white;
            -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        }
        &::after{
            display: inline-block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 0;
            margin-left: -20px;
            padding-left: 3px;
            padding-top: 1px;
            font-size: 11px;
            color: variables.$theme-font-color;
        }
    }
    input[type="checkbox"]{
        opacity: 0;
        &:focus{
            outline-offset: -2px;
            & + label{
                &::before{
                    outline-offset: -2px;
                }
            }
        }
    }
    input[type="checkbox"]{
        &:checked{
            & + label{
                &::before{
                    font-family: variables.$font-themify;
                    content: "\e64c";
                    text-align: center;
                    line-height: 1.2;
                }
            }
        }
        &:disabled{
            & + label{
                opacity: 0.65;
                &::before{
                    background-color: variables.$light-color;
                    cursor: not-allowed;
                }
            }
        }
    }
    .checkbox-circle{
        label{
            &::before{
                border-radius: 50%;
            }
        }
    }
    .checkbox-inline{
        margin-top: 0;
    }
}
.m-squar{
    label {
        &::before {
            border-radius: 0;
        }
    }
    .checkbox-circle{
        label{
            &::before{
                border-radius: 50%;
            }
        }
    }
    .checkbox-inline{
        margin-top: 0;
    }
}

@each $btn-name, $btn-color in (primary, variables.$primary-color),
        (secondary, variables.$secondary-color) ,
        (success, variables.$success-color),
        (danger, variables.$danger-color),
        (info, variables.$info-color),
        (light, variables.$light-color),
        (dark, variables.$dark-color),
        (warning, variables.$warning-color) {
    .checkbox-#{$btn-name}{
        input[type="checkbox"]
        {
            &:checked{
                & + label{
                    &::before{
                        // background-color: $btn-color;
                        border-color: $btn-color;
                        color: $btn-color;
                    }
                    &::after{
                        color: variables.$white;
                    }
                }
            }
        }
    }
}
@each $btn-name, $btn-color in   (primary, variables.$primary-color),
        (secondary, variables.$secondary-color) ,
        (success, variables.$success-color),
        (danger, variables.$danger-color),
        (info, variables.$info-color),
        (light, variables.$light-color),
        (dark, variables.$dark-color),
        (warning, variables.$warning-color) {
    .checkbox-solid-#{$btn-name}{
        label{
            &:before{
                background-color: $btn-color;
                border-color: $btn-color;
                color: variables.$white;
            }
        }
        input[type="checkbox"]
        {
            &:checked{
                & + label{
                    &::before{
                        background-color: $btn-color;
                        border-color: $btn-color;
                        color: variables.$white;
                    }
                    &::after{
                        color: variables.$white;
                    }
                }
            }
        }
    }

}
@each $btn-name, $btn-color in (primary, variables.$primary-color),
        (secondary, variables.$secondary-color) ,
        (success, variables.$success-color),
        (danger, variables.$danger-color),
        (info, variables.$info-color),
        (light, variables.$light-color),
        (dark, variables.$dark-color),
        (warning, variables.$warning-color) {
    .checkbox-#{$btn-name}{
        label{
            &::before{
                border-color:$btn-color;
            }
        }

    }
}
.m-checkbox-inline{
    .checkbox{
        display: inline-block;
        margin-right: 15px;
        &:last-child{
            margin-right: 0;
        }
    }
    .radio{
        display: inline-block;
    }
    label{
        margin-right:20px;
    }
}
.radio{
    label{
        display: inline-block;
        position: relative;
        padding-left: 5px;
        cursor: pointer;
        &::before{
            content: "";
            display: inline-block;
            position: absolute;
            width: 19px;
            height: 19px;
            left: 0;
            margin-left: -20px;
            border: 1px solid variables.$light-gray;
            border-radius: 50%;
            background-color: variables.$white;
            -webkit-transition: border 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out;
            transition: border 0.15s ease-in-out;
        }
        &::after{
            display: inline-block;
            position: absolute;
            content: " ";
            width: 9px;
            height: 9px;
            left: 5px;
            top: 5px;
            margin-left: -20px;
            border-radius: 50%;
            background-color: variables.$dark-gray;
            -webkit-transform: scale(0, 0);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        }
    }
    input[type="radio"]{
        opacity: 0;
        &:focus{
            & + label{
                &::before{
                    outline-offset: -2px;
                }

            }
        }
        &:checked{
            & + label{
                &::after{
                    -webkit-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    -o-transform: scale(1, 1);
                    transform: scale(1, 1);
                }

            }
        }
        &:disabled{
            & + label{
                opacity: 0.65;
                &::before{
                    cursor: not-allowed;
                }
            }
        }
    }
    .radio-inline{
        margin-top: 0;
    }
}

@each $btn-name, $btn-color in (primary, variables.$primary-color),
        (secondary, variables.$secondary-color) ,
        (success, variables.$success-color),
        (danger, variables.$danger-color),
        (info, variables.$info-color),
        (light, variables.$light-color),
        (dark, variables.$dark-color),
        (warning, variables.$warning-color) {
    .radio-#{$btn-name}{
        input[type="radio"]
        {
            & + label{
                &::before{
                    border-color: $btn-color;
                }
                &::after{
                    background-color: $btn-color;
                }
            }
            &:checked{
                & + label{
                    &::before{
                        border-color: $btn-color;
                    }
                    &::after{
                        background-color: $btn-color;
                    }
                }
            }
        }
    }
}
/**=====================
     28. Radio CSS Ends
==========================**/